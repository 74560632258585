import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function About() {
  var joiningDate= new Date("05/19/2018");
  const current = new Date();
  let year = current.getFullYear();
var experienceYears = (year-joiningDate.getFullYear());

const location = useLocation();
useEffect(() => {
  window.scrollTo(0, 0);
}, [location]);



  return (
    <div className="pb-5">
      <div className="navrest">
        <nav class="navbar navbar-expand-lg text-center navbar-light bg-transparent">
          <button
            class="navbar-toggler custom-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse text-center "
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <Link class="nav-link" to="/">
                  Home <i class="fa fa-home"></i>
                </Link>
              </li>
              <li class="nav-item active ">
                <Link class="nav-link" to="/about">
                  About <i class="fa-solid fa-circle-info"></i>
                </Link>
              </li>
              <li class="nav-item  ">
                <Link class="nav-link" to="/blog">
                  Write Up <i class="fa-solid fa-code"></i>
                </Link>
              </li>
              <li class="nav-item  ">
                <Link class="nav-link" to="/contact">
                  contact <i class="fa-solid fa-phone"></i>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <section id="about" className="rounded ">
        <section className="main mt-5">
          <div className="mt-5">
            <div className=" m-5  justify-content-center ">
              <div className="row container d-flex justify-content-center mt-5">
                <div className="col">
                  <div className="dp-main d-flex justify-content-center  rounded-circle">
                    <img
                      className="dp rounded-circle "
                      src={require("../assets/img/dp.jpeg")}
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <div className="col d-flex ">
                  <div className="mt-5">
                    <h2 className="title3">KAPIL GURAV</h2>
                    <p className="mt-5 text-light">
                      <h3 className="ct"> {">"} Author's Bio</h3>
                      Kapil Gurav is a Security consultant at Payatu. He has experience in Web, API, Android, iOS, and
                       Hardware penetration testing with {experienceYears} years of experience. He loves contributing, being a part
                        of security communities, and helping people get started in InfoS
                    </p>
                    <div className="d-flex container justify-content-center mt-5">
                      <div class="rounded-pill text-white  p-2 hire">
                        <span className=" ct">
                        <Link to="/contact">
                        Get in touch
                        </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="">
          <div>
            <div className="d-flex m-5 justify-content-center">
              <div className="row ">
                <div className="col text-center p-5 exp">
                  <span className="h1 ct">
                    <i class="fa fa-calendar  mt-3 card-img-top"></i>
                    <h3>{experienceYears} </h3>
                    <h5 class="card-title">EXPERIENCE</h5>
                  </span>
                </div>
                <div className="col  text-center p-5 exp">
                  <span className="h1 ct">
                    <i class="fa fa-bug  mt-3 card-img-top"></i>
                    <h3>100+ </h3>
                    <h5 class="card-title">TESTINGS</h5>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div>
            <div className="d-flex m-5  text-center">
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div className="mt-5">
                    <p className="mt-5 text-light text-start">
                      <h3 className="ct"> {">"} What skils do I have? </h3>
                      I possess expertise in identifying and mitigating security threats through the use of
                       penetration testing and vulnerability assessments. My strong understanding of network
                        and system security, as well as hacking tools and techniques, allows me to effectively
                         protect against potential attacks
                    </p>
                  </div>
                </div>
                <div className="col-md-6 p-5 d-flex  justify-content-center">
                    <div className="row  ">
                     
                    <div className="col-md-4">
                
                          <div className="col-md-6">
                            <img
                              className=" card skill-mini"
                              src={require("../assets/img/skills/web.png")}
                              alt=""
                            />
                            <a href="#"className="p-5"> WEB</a>
                          </div>
                          <div className="col-md-6 ">
                            <img
                              className=" card skill-mini"
                              src={require("../assets/img/skills/api.png")}
                              alt=""
                            />
                            <a href="#"className="p-5">API</a>
                          </div>
                      
                    </div>
                    <div className="col-md-4">
                
                        <div className="col-md-4 hardware">
                            <img
                              className=" card skill-mini"
                              src={require("../assets/img/skills/exploithardware.jpeg")}
                              alt=""
                            />
                            <a href="#" className="p-3">HARDWARE</a>
                          </div>
                        
                       
                    </div>
                    <div className="col-md-4">
                    
                        <div className="col-md-6">
                            <img
                              className=" card skill-mini"
                              src={require("../assets/img/skills/android.png")}
                              alt=""
                            />
                            <a href="#"className="p-4"> ANDROID</a>
                          </div>
                          <div className="col-md-6" >
                            <img
                              className=" card skill-mini"
                              src={require("../assets/img/skills/ios.webp")}
                              alt=""
                            />
                            <a href="#"className="p-5 font-weight-bold">IOS</a>
                          </div>
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="">
          <div>
            <div className=" m-5  justify-content-center">
              <div className="row mt-5">
                <div className="col mt-3">
                  <div className="mt-5">
                    <p className="mt-5 text-light ">
                      <h3 className="ct"> {">"} Portfolio </h3>
                      "Welcome to my blog, where I share my experiences and insights as a seasoned penetration tester. With over 4 years of experience in testing web, Android, iOS, API, hardware, and firmware, I bring a unique perspective to the world of cybersecurity. In this blog, I'll share my knowledge and practical approaches to finding and fixing vulnerabilities, as well as discussing the latest trends and advancements in the field. Whether you're a security professional or simply interested in learning more about penetration testing, you'll find valuable information here. Let's explore the exciting world of infosec together."
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col mt-5 ">
                  <div class="exp ct ">
                    <img
                      class="card-img-top cardsmini"
                      src={require("../assets/img/blog/Android-system-architecture.jpg")}
                      alt="Card image cap"
                    />
                    <div class="card-body text-center p-1">
                      <h5 class="card-title">
                      <Link to="/uaa">
                        Understand the Architecture of Android
                      </Link>

                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col mt-5 ">
                  <div class="exp ct  ">
                    <img
                      class="card-img-top cardsmini"
                      src={require("../assets/img/blog/genomotion01.png")}
                      alt="Card image cap"
                    />
                    <div class="card-body text-center p-1">
                      <h5 class="card-title">
                      <Link to="/gds">
                        Genymotion Device Setup ( Android )
                      </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col mt-5 ">
                  <div class="exp ct">
                    <img
                      class="card-img-top cardsmini"
                      src={require("../assets/img/blog/uid-01.png")}
                      alt="Card image cap"
                    />
                    <div class="card-body text-center p-3">
                      <h5 class="card-title">
                      <Link to="/oscp">
                      OSCP Journey
                      </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="d-flex container justify-content-center mb-5">
                  <div class="p-2  knowmore rounded-pill text-center">
                    <Link to="/blog" className="text-light p-2">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
